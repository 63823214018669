/* purgecss start ignore */
@tailwind base;
@tailwind components;

h2, h3, h4, h5 {
    @apply break-words overflow-ellipsis hyphens-auto
}
article a {
  @apply underline underline-offset-[0.2em]
}
p {
  @apply text-lg lg:text-xl;
}

ul {
    @apply list-disc my-[1.2em] pl-[1.6em]
}

ul > li {
    @apply my-[0.6em] text-lg lg:text-xl
}

abbr {
 @apply underline decoration-dotted underline-offset-[0.2em]
}

a[href], button, *[tabindex="-1"], *[tabindex="0"] {
  @apply focus-visible:outline focus-visible:outline-8 focus-visible:outline-offset-4 focus-visible:outline-darkerblue focus-visible:brightness-95 dark:focus-visible:outline-white
}

html {
    -webkit-tap-highlight-color: transparent;
}

.pb-safe {
    padding-bottom: env(safe-area-inset-bottom);
}

.fade-enter {
    opacity: 0;
}

.fade-enter-active {
    opacity: 1;
    transition: opacity 400ms;
}

.fade-exit {
    opacity: 1;
}

.fade-exit-active {
    opacity: 0;
    transition: opacity 400ms;
}

iframe[src*="youtube"] {
    aspect-ratio: 16 / 9;
    width: 100%;
}

.error {
    @apply border-red !important;
}


/* purgecss end ignore */
@tailwind utilities;
